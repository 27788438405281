import SendDigest from "./SendDigest";

const DigestView = ({ digest }: { digest: any }) => {
  return (
    <div className="border-2 border-primary rounded-lg p-4">
      <div className="flex justify-between">
        <div>
          <div className="text-secondary text-lg font-semibold">{digest.user_email}</div>
          <div className="text-secondary text-sm">{digest.ingest_date}</div>
        </div>
        <div className="flex space-x-4">
          <SendDigest digest={digest} qa={true}/>
          <SendDigest digest={digest} qa={false}/>
        </div>
      </div>
      <div className="text-secondary text-sm">{digest.digest}</div>
    </div>
  );
}

export default DigestView;