import BackArrow          from "../components/BackArrow";
import { useGlobalStore } from "../utils/store";

// const selectedUser = {
//   "_id": "665d8ca484710b7963893546",
//   "email": "a@gmail.com",
//   "name": "Stefan",
//   "age": 26,
//   "industry": "IT",
//   "postal_code": 11060,
//   "country": "Serbia",
//   "city": "Belgrade",
//   "interests": [
//     "AAPL",
//     "TSLA",
//     "MSFT",
//   ],
//   "deleted": false,
// };

const UserViewPage = () => {
  const selectedUser = useGlobalStore( ( state: any ) => state.selectedUser );
  
  return (
    <div>
      <BackArrow text="All Users"/>
      <br/>
      <div className="px-4 sm:px-0">
        <h3 className="text-base font-semibold leading-7 text-white">User Information</h3>
        <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-400">All data fields</p>
      </div>
      <div className="mt-6 border-t border-white/10">
        <dl className="divide-y divide-white/10">
          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt className="text-sm font-medium leading-6 text-white">Full name</dt>
            <dd className="mt-1 text-sm leading-6 text-gray-400 sm:col-span-2 sm:mt-0">{selectedUser.name}</dd>
          </div>
          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt className="text-sm font-medium leading-6 text-white">Email address</dt>
            <dd className="mt-1 text-sm leading-6 text-gray-400 sm:col-span-2 sm:mt-0">{selectedUser.email}</dd>
          </div>
          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt className="text-sm font-medium leading-6 text-white">Age</dt>
            <dd className="mt-1 text-sm leading-6 text-gray-400 sm:col-span-2 sm:mt-0">{selectedUser.age}</dd>
          </div>
          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt className="text-sm font-medium leading-6 text-white">Industry</dt>
            <dd className="mt-1 text-sm leading-6 text-gray-400 sm:col-span-2 sm:mt-0">{selectedUser.industry}</dd>
          </div>
          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt className="text-sm font-medium leading-6 text-white">Postal Code</dt>
            <dd className="mt-1 text-sm leading-6 text-gray-400 sm:col-span-2 sm:mt-0">{selectedUser.postal_code}</dd>
          </div>
          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt className="text-sm font-medium leading-6 text-white">Country</dt>
            <dd className="mt-1 text-sm leading-6 text-gray-400 sm:col-span-2 sm:mt-0">{selectedUser.country}</dd>
          </div>
          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt className="text-sm font-medium leading-6 text-white">City</dt>
            <dd className="mt-1 text-sm leading-6 text-gray-400 sm:col-span-2 sm:mt-0">{selectedUser.city}</dd>
          </div>
          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt className="text-sm font-medium leading-6 text-white">Interests</dt>
            <dd className="mt-1 text-sm leading-6 text-gray-400 sm:col-span-2 sm:mt-0">{selectedUser.interests.join(
              ", " )}</dd>
          </div>
        </dl>
      </div>
    </div>
  );
};

export default UserViewPage;
