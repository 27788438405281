import { BeakerIcon, PaperAirplaneIcon }              from "@heroicons/react/24/solid";
import { FC, useState }                               from "react";
import { useMutation }                                from "react-query";
import { AdminRequests, getBasicAuth, submitRequest } from "../../utils/routes";
import ConfirmationDialog                             from "../ConfirmationDialog";

const SendDigest: FC<any> = ( { digest, qa } ) => {
  const [ open, setOpen ] = useState( false );
  
  const sendDigestMutation = useMutation( {
    mutationFn: submitRequest,
    onSuccess: ( data: any ): void => {
      console.log( "digest sending done, qa:", qa );
      console.log( "data:", data );
    },
  } );
  
  const sendDigest = (): void => {
    sendDigestMutation.mutate( {
      queryKey: [
        AdminRequests.SendDigest,
        { qa, digest_id: digest._id },
        undefined,
        getBasicAuth(
          localStorage.getItem( "email" ) as string,
          localStorage.getItem( "password" ) as string,
        ),
      ],
    } );
  };
  
  return (
    <>
      {qa
        ? <BeakerIcon
          className="h-6 w-6 text-tertiary hover:text-tertiaryShadow hover:cursor-pointer"
          aria-hidden="true"
          onClick={() => sendDigest()}
        />
        : <PaperAirplaneIcon
          className="h-6 w-6 text-success hover:text-successShadow hover:cursor-pointer"
          aria-hidden="true"
          onClick={() => setOpen( true )}
        />
      }
      <ConfirmationDialog
        title="Send Digest"
        text={`Are you sure you want to send this digest to ${digest.user}?`}
        onConfirm={() => {
          sendDigest();
        }}
        open={open}
        setOpen={setOpen}
        danger={false}
      />
    </>
  );
};
export default SendDigest;