import React, { useState }                            from "react";
import { useQuery }                                   from "react-query";
import MultiCalcGraph
                                                      from "../components/calcs/MultiCalcGraph";
import TickerInput
                                                      from "../components/calcs/TickerInput";
import Loading                                        from "../components/Loading";
import { AdminRequests, getBasicAuth, submitRequest } from "../utils/routes";

const formatDate = ( date: Date ): string => {
  return date.toISOString()
    .split( "T" )[0];
};

const CalcsPage: React.FC = () => {
  const today          = new Date();
  const threeMonthsAgo = new Date(
    today.getFullYear(), today.getMonth() - 3, today.getDate() );
  
  const [ ticker, setTicker ]     = useState( "" );
  const [ fromDate, setFromDate ] = useState( formatDate( threeMonthsAgo ) );
  const [ toDate, setToDate ]     = useState( formatDate( today ) );
  const [ clicked, setClicked ]   = useState( false );
  
  const {
          isLoading,
          data: chartData,
          isError,
          refetch: fetchCalcs,
        } = useQuery(
    [
      AdminRequests.GetAllCalcs,
      { start_date: fromDate, end_date: toDate, ticker },
      undefined,
      getBasicAuth(
        localStorage.getItem( "email" ) as string,
        localStorage.getItem( "password" ) as string,
      ),
    ],
    submitRequest,
    {
      // Refetch every hour
      staleTime: 1000 * 60 * 60,
      enabled: false,
    },
  );
  
  return (
    <div className="p-4">
      <TickerInput
        inputValue={ticker}
        setInputValue={( e ) => {
          setTicker( e );
          setClicked( false );
        }}
        handleEnter={() => {
          console.log( "value:", ticker );
          setClicked( true );
          fetchCalcs();
        }}
      />
      <h1 className="text-base font-semibold leading-6 text-secondary my-4">Calcs</h1>
      {!clicked ? <></> : isLoading || !chartData ? <Loading/> : isError ?
        <div>Failed to load calcs</div> :
        <MultiCalcGraph
          fromDate={fromDate}
          setFromDate={setFromDate}
          toDate={toDate}
          setToDate={setToDate}
          data={chartData}
        />}
    </div>
  );
};

export default CalcsPage;