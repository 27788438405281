import { EyeIcon }                                               from "@heroicons/react/24/outline";
import {
  PencilSquareIcon,
}                                                                from "@heroicons/react/24/solid";
import { useQuery }                                              from "react-query";
import { useNavigate }                                           from "react-router-dom";
import DeleteUser
                                                                 from "../components/DeleteUser";
import { AdminRequests, AppRoutes, getBasicAuth, submitRequest } from "../utils/routes";
import { useGlobalStore }                                        from "../utils/store";

export default function UsersTable() {
  const navigate = useNavigate() as any;
  
  const setSelectedUser = useGlobalStore( ( state: any ) => state.setSelectedUser );
  
  const { isLoading, data: users, isError } = useQuery(
    [
      AdminRequests.GetAllUsers,
      undefined,
      undefined,
      getBasicAuth(
        localStorage.getItem( "email" ) as string,
        localStorage.getItem( "password" ) as string,
      ),
    ],
    submitRequest,
    { staleTime: 0, refetchInterval: 500 },
  );
  
  return !users ? <></> : (
    <div className="bg-primary">
      <div className="mx-auto max-w-7xl">
        <div className="bg-primary py-10">
          <div className="px-4 sm:px-6 lg:px-8">
            <div className="sm:flex sm:items-center">
              <div className="sm:flex-auto">
              </div>
              <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
                <button type="button" className="block rounded-md bg-tertiary px-3 py-2 text-center text-sm font-semibold text-secondary hover:bg-tertiaryShadow focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-tertiary"
                        onClick={() => navigate( AppRoutes.UserNew )}
                >
                  {/*<PlusCircleIcon className="h-5 w-5 mr-2" aria-hidden="true"></PlusCircleIcon>*/}
                  Add User
                </button>
              </div>
            </div>
            <div className="mt-8 flow-root">
              <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                  <table className="min-w-full divide-y divide-primaryShadow">
                    <thead>
                    <tr>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-secondary">
                        Email
                      </th>
                      <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-secondary sm:pl-0">
                        Name
                      </th>
                      <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-secondary sm:pl-0">
                        Created At
                      </th>
                      <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-secondary sm:pl-0">
                        Updated At
                      </th>
                      <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-secondary sm:pl-0">
                        Country
                      </th>
                      <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-secondary sm:pl-0">
                        Industry
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-secondary">
                        Interests
                      </th>
                      <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0">
                        <span className="sr-only">View</span>
                      </th>
                      <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0">
                        <span className="sr-only">Edit</span>
                      </th>
                      <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0">
                        <span className="sr-only">Delete</span>
                      </th>
                    </tr>
                    </thead>
                    <tbody className="divide-y divide-primaryShadow">
                    {users.map( ( user: any ) => (
                      <tr key={user.email}>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-secondary">{user.email}</td>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-secondary sm:pl-0">
                          {user.name}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-secondary">{user.created_at}</td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-secondary">{user.updated_at}</td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-secondary">{user.country}</td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-secondary">{user.industry}</td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-secondary">{user.interests ? user.interests.join(
                          ", " ) : ""}</td>
                        <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                          <EyeIcon className="h-6 w-6 text-tertiary hover:text-tertiaryShadow hover:cursor-pointer" aria-hidden="true"
                                   onClick={() => {
                                     setSelectedUser( user );
                                     navigate( AppRoutes.UserView );
                                   }}/>
                        </td>
                        <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                          <PencilSquareIcon className="h-6 w-6 text-tertiary hover:text-tertiaryShadow hover:cursor-pointer" aria-hidden="true"
                                            onClick={() => {
                                              setSelectedUser( user );
                                              navigate( AppRoutes.UserEdit );
                                            }}/>
                        </td>
                        <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                          <DeleteUser user={user}/>
                        </td>
                      </tr>
                    ) )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
