import { useState }                                   from "react";
import { useMutation }                                from "react-query";
import { AdminRequests, getBasicAuth, submitRequest } from "../utils/routes";
import ConfirmationDialog                             from "./ConfirmationDialog";

const PurgeQueues = () => {
  const [ open, setOpen ] = useState( false );
  
  const purgeQueuesMutation = useMutation( {
    mutationFn: submitRequest,
    onSuccess: ( data: any ): void => {
      console.log( "purge queues done" );
      console.log( "data:", data );
    },
  } );
  
  const purgeQueues = (): void => {
    purgeQueuesMutation.mutate( {
      queryKey: [
        AdminRequests.PurgeQueues,
        undefined,
        undefined,
        getBasicAuth(
          localStorage.getItem( "email" ) as string,
          localStorage.getItem( "password" ) as string,
        ),
      ],
    } );
  };
  
  return (
    <>
      <button
        type="button"
        className="block rounded-md bg-danger px-3 py-2 text-center text-sm font-semibold text-primary hover:bg-dangerShadow focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-tertiary"
        onClick={() => setOpen( true )}
      >
        Purge Queues
      </button>
      <ConfirmationDialog
        title="Purge Queues"
        text={`Purge all queues?`}
        onConfirm={() => {
          console.log( "Queues purged." );
          purgeQueues();
        }}
        open={open}
        setOpen={setOpen}
        danger={true}
      />
    </>
  );
};
export default PurgeQueues;