import React, { useState, useMemo } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

type DataPoint = {
  date: string;
  [key: string]: number | string;
};

type DataSet = {
  [key: string]: { date: string; value: number }[];
};

const generateColors = (count: number) => {
  const colors = [];
  for (let i = 0; i < count; i++) {
    colors.push(`hsl(${(i * 360) / count}, 80%, 60%)`);
  }
  return colors;
};

const MultiCalcGraph: React.FC<{
  data: DataSet;
  fromDate: string;
  setFromDate: (date: string) => void;
  toDate: string;
  setToDate: (date: string) => void;
}> = ({
  data,
  fromDate,
  setFromDate,
  toDate,
  setToDate
}) => {
  const lineNames = Object.keys(data).filter(key => key !== 'close');
  const colors = generateColors(lineNames.length);
  
  const [visibleLines, setVisibleLines] = useState<{ [key: string]: boolean }>(
    Object.fromEntries(lineNames.map(line => [line, false]))
  );
  
  const toggleLine = (lineName: string) => {
    setVisibleLines(prev => ({ ...prev, [lineName]: !prev[lineName] }));
  };
  
  const formattedData = useMemo(() => {
    const dates = data.close.map(point => point.date);
    return dates.map(date => {
      const dataPoint: DataPoint = { date };
      lineNames.forEach(lineName => {
        const value = data[lineName].find(point => point.date === date)?.value;
        if (value !== undefined) {
          dataPoint[lineName] = value;
        }
      });
      return dataPoint;
    });
  }, [data, lineNames]);
  
  const filteredData = useMemo(() => {
    return formattedData.filter(point => point.date >= fromDate && point.date <= toDate);
  }, [formattedData, fromDate, toDate]);
  
  return (
    <div className="flex flex-col items-center w-full bg-gray-800 p-4 rounded-lg">
      <div className="w-full max-w-sm mb-4 flex justify-between">
        <input
          type="date"
          value={fromDate}
          onChange={(e) => setFromDate(e.target.value)}
          className="border rounded p-1 bg-gray-700 text-white"
        />
        <input
          type="date"
          value={toDate}
          onChange={(e) => setToDate(e.target.value)}
          className="border rounded p-1 bg-gray-700 text-white"
        />
      </div>
      <ResponsiveContainer width="100%" height={500}>
        <LineChart data={filteredData}>
          <CartesianGrid strokeDasharray="3 3" stroke="#555" />
          <XAxis dataKey="date" stroke="#fff" />
          <YAxis stroke="#fff" />
          <Tooltip contentStyle={{ backgroundColor: '#333', borderColor: '#555' }} />
          <Legend wrapperStyle={{ color: '#fff' }} />
          {lineNames.map((lineName, index) => (
            visibleLines[lineName] && (
              <Line
                key={lineName}
                type="monotone"
                dataKey={lineName}
                name={lineName.replace(/_/g, ' ')}
                stroke={colors[index]}
                dot={false}
                activeDot={{ r: 2 }}
              />
            )
          ))}
        </LineChart>
      </ResponsiveContainer>
      <div className="mt-4 flex flex-wrap justify-center">
        {lineNames.map((lineName, index) => (
          <button
            key={lineName}
            onClick={() => toggleLine(lineName)}
            className={`
              m-1 px-2 py-1 rounded text-white font-semibold
              transition-all duration-200 ease-in-out
              ${visibleLines[lineName] ? 'border-2 border-white' : 'border-2 border-transparent'}
            `}
            style={{
              backgroundColor: colors[index],
              opacity: visibleLines[lineName] ? 1 : 0.6,
            }}
          >
            {lineName.replace(/_/g, ' ')}
          </button>
        ))}
      </div>
    </div>
  );
};

export default MultiCalcGraph;