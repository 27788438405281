import { BackspaceIcon }                                         from "@heroicons/react/24/solid";
import { FC, useState }                                          from "react";
import { useMutation }                                           from "react-query";
import { useNavigate }                                           from "react-router-dom";
import Notification
                                                                 from "../components/Notification";
import { AdminRequests, AppRoutes, getBasicAuth, submitRequest } from "../utils/routes";
import ConfirmationDialog
                                                                 from "./ConfirmationDialog";

const DeleteUser: FC<any> = ( { user } ) => {
  const navigate = useNavigate() as any;
  
  const [ open, setOpen ] = useState( false );
  
  const deleteUserMutation = useMutation( {
    mutationFn: submitRequest,
    onSuccess: ( data: any ): void => {
      console.log( "user deleted" );
      console.log( "data:", data );
      navigate( AppRoutes.Users );
    },
  } );
  
  const deleteUser = (): void => {
    deleteUserMutation.mutate( {
      queryKey: [
        AdminRequests.DeleteUser,
        undefined,
        { email: user.email },
        getBasicAuth(
          localStorage.getItem( "email" ) as string,
          localStorage.getItem( "password" ) as string,
        ),
      ],
    } );
  };
  
  return (
    <>
      <BackspaceIcon
        className="h-6 w-6 text-danger hover:text-dangerShadow hover:cursor-pointer"
        aria-hidden="true"
        onClick={() => setOpen( true )}
      />
      <ConfirmationDialog
        title="Delete User"
        text={"Are you sure you want to delete " + ( user.name || "this unnamed user" ) + "?"}
        onConfirm={() => {
          deleteUser();
          console.log( "User " + user + " deleted" );
        }}
        open={open}
        setOpen={setOpen}
        danger={true}
      />
      <Notification title={"Success"} message={"User deleted."} error={false}/>
    </>
  );
};
export default DeleteUser;