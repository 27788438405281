import { useState }                                from "react";
import { useMutation }                             from "react-query";
import { useNavigate }                             from "react-router-dom";
import BackArrow                                                 from "../components/BackArrow";
import { AdminRequests, AppRoutes, getBasicAuth, submitRequest } from "../utils/routes";

const NewUserPage = () => {
  const navigate = useNavigate();
  
  const [ userData, setUserData ] = useState( {
    "email": "",
    "name": "",
    "age": "",
    "industry": "",
    "postal_code": "",
    "country": "",
    "city": "",
    "interests": "",
  } );
  
  const createUserMutation = useMutation( {
    mutationFn: submitRequest,
    onSuccess: ( data: any ): void => {
      console.log( "user created" );
      console.log( "data:", data );
      navigate( AppRoutes.Users );
    },
  } );
  
  const createUser = (): void => {
    createUserMutation.mutate( {
      queryKey: [
        AdminRequests.CreateUser,
        undefined,
        userData,
        getBasicAuth(
          localStorage.getItem( "email" ) as string,
          localStorage.getItem( "password" ) as string,
        ),
      ],
    } );
  };
  
  const handleChange = ( e: any ) => {
    const { name, value } = e.target;
    setUserData( { ...userData, [name]: value } );
  };
  
  const handleSubmit = ( e: any ) => {
    e.preventDefault();
    createUser();
    console.log( "user created:", userData );
  };
  
  return (
    <form onSubmit={handleSubmit}>
      <BackArrow text="All Users"/>
      <br/>
      <div className="space-y-12">
        <div className="border-b border-white/10 pb-12">
          <h2 className="text-base font-semibold leading-7 text-white">User</h2>
          <p className="mt-1 text-sm leading-6 text-gray-400">
            New user information
          </p>
          
          <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
            <div className="sm:col-span-4">
              <label htmlFor="name" className="block text-sm font-medium leading-6 text-white">
                Full name
              </label>
              <div className="mt-2">
                <input
                  type="text"
                  name="name"
                  id="name"
                  value={userData.name}
                  onChange={handleChange}
                  className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
            
            <div className="sm:col-span-4">
              <label htmlFor="email" className="block text-sm font-medium leading-6 text-white">
                Email address
              </label>
              <div className="mt-2">
                <input
                  id="email"
                  name="email"
                  type="email"
                  value={userData.email}
                  onChange={handleChange}
                  className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
            
            <div className="sm:col-span-2">
              <label htmlFor="age" className="block text-sm font-medium leading-6 text-white">
                Age
              </label>
              <div className="mt-2">
                <input
                  type="number"
                  name="age"
                  id="age"
                  value={userData.age}
                  onChange={handleChange}
                  className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
            
            <div className="sm:col-span-2">
              <label htmlFor="industry" className="block text-sm font-medium leading-6 text-white">
                Industry
              </label>
              <div className="mt-2">
                <input
                  type="text"
                  name="industry"
                  id="industry"
                  value={userData.industry}
                  onChange={handleChange}
                  className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
            
            <div className="sm:col-span-2">
              <label htmlFor="postal_code" className="block text-sm font-medium leading-6 text-white">
                Postal Code
              </label>
              <div className="mt-2">
                <input
                  type="text"
                  name="postal_code"
                  id="postal_code"
                  value={userData.postal_code}
                  onChange={handleChange}
                  className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
            
            <div className="sm:col-span-2">
              <label htmlFor="country" className="block text-sm font-medium leading-6 text-white">
                Country
              </label>
              <div className="mt-2">
                <input
                  type="text"
                  name="country"
                  id="country"
                  value={userData.country}
                  onChange={handleChange}
                  className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
            
            <div className="sm:col-span-2">
              <label htmlFor="city" className="block text-sm font-medium leading-6 text-white">
                City
              </label>
              <div className="mt-2">
                <input
                  type="text"
                  name="city"
                  id="city"
                  value={userData.city}
                  onChange={handleChange}
                  className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
            
            <div className="col-span-full">
              <label htmlFor="interests" className="block text-sm font-medium leading-6 text-white">
                Interests
              </label>
              <div className="mt-2">
                <input
                  type="text"
                  name="interests"
                  id="interests"
                  value={userData.interests}
                  onChange={( e: any ) => setUserData( {
                    ...userData,
                    interests: e.target.value.split( "," )
                      .map( ( item: any ) => item.trim() ),
                  } )}
                  className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                />
              </div>
              <p className="mt-3 text-sm leading-6 text-gray-400">Enter interests separated by commas.</p>
            </div>
          </div>
        </div>
        
        <div className="mt-6 flex items-center justify-end gap-x-6">
          <button type="button" className="text-sm font-semibold leading-6 text-white cursor-pointer" onClick={() => navigate(
            -1 )}>
            Cancel
          </button>
          <button
            type="submit"
            className="rounded-md bg-indigo-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
          >
            Save
          </button>
        </div>
      </div>
    </form>
  );
};

export default NewUserPage;
