import { useQuery }                                   from "react-query";
import Loading                                        from "../components/Loading";
import { AdminRequests, getBasicAuth, submitRequest } from "../utils/routes";

const ScalarMetrics = () => {
  const {
          isLoading,
          data,
          isError,
        } = useQuery(
    [
      AdminRequests.GetDashboardScalars,
      undefined,
      undefined,
      getBasicAuth(
        localStorage.getItem( "email" ) as string,
        localStorage.getItem( "password" ) as string,
      ),
    ],
    submitRequest,
    {
      staleTime: 0,
      refetchInterval: 500,
    },
  );
  
  // transform key: value in data to name: key, stat: value
  const stats: any[] = !!data ? Object.keys( data )
    .map( ( key: string ): any => {
      return {
        name: key
          .replace( /_/g, " " )
          .replace( /\b\w/g, ( char: any ) => char.toUpperCase() ),
        stat: data[key],
      };
    } ) : [ {} ];
  
  if ( isLoading ) {
    return <Loading/>;
  }
  
  if ( isError ) {
    return <div>Error</div>;
  }
  
  return (
    <div>
      <h3 className="text-base font-semibold leading-6 text-primary">Scalar Metrics</h3>
      <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-3">
        {stats.map( ( item ) => (
          // make this have an outline
          <div key={item.name} className="overflow-hidden rounded-lg bg-primary px-4 py-5 shadow sm:p-6 sm:rounded-lg hover:bg-primaryShadow border-2 border-primaryShadow">
            <dt className="truncate text-sm font-medium text-secondary">{item.name}</dt>
            <dd className="mt-1 text-3xl font-semibold tracking-tight text-secondary">{item.stat}</dd>
          </div>
        ) )}
      </dl>
    </div>
  );
};
export default ScalarMetrics;