import ScalarMetrics from "../components/ScalarMetrics";

const DashboardPage = () => {
  return (
    <>
      <h1 className="text-base font-semibold leading-6 text-secondary">Dashboard</h1>
      <ScalarMetrics/>
    </>
  );
};

export default DashboardPage;