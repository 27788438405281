import { EyeIcon }    from "@heroicons/react/24/outline";
import { FC }         from "react";
import Chart          from "../components/Chart";
import InstrumentView from "../components/InstrumentView";

type InstrumentResultsTableProps = {
  selectedInstrument: any,
  setSelectedInstrument: any,
  instruments: any[] | undefined
}

const InstrumentResultsTable: FC<InstrumentResultsTableProps> = ( {
  selectedInstrument,
  setSelectedInstrument,
  instruments,
} ) => {
  return !instruments ? <></> : (
    <div>
      <div className="mx-auto max-w-7xl">
        <div className="bg-primary py-10">
          <div className="px-4 sm:px-6 lg:px-8">
            <div className="mt-8 flow-root">
              <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                  <table className="min-w-full divide-y divide-primaryShadow">
                    <thead>
                    <tr>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-secondary">
                        Ticker
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-secondary">
                        Name
                      </th>
                      <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-secondary sm:pl-0">
                        Type
                      </th>
                      <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0">
                        <span className="sr-only">View</span>
                      </th>
                    </tr>
                    </thead>
                    <tbody className="divide-y divide-primaryShadow">
                    {instruments!.map( ( instrument ) => (
                      <tr key={instrument.ticker}>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-secondary">{instrument.ticker}</td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-secondary">{instrument.name}</td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-secondary">{instrument.ticker_type_name}</td>
                        <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                          <EyeIcon className="h-6 w-6 text-tertiary hover:text-tertiaryShadow hover:cursor-pointer" aria-hidden="true"
                                   onClick={() => setSelectedInstrument( instrument )}/>
                        </td>
                      </tr>
                    ) )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {!!selectedInstrument ? <InstrumentView instrument={selectedInstrument}/> : <></>}
    </div>
  );
};
export default InstrumentResultsTable;