import React                                from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import "./App.css";

import { Route, Routes }   from "react-router-dom";
import CalcsPage           from "./pages/CalcsPage";
import DashboardPage       from "./pages/DashboardPage";
import DigestsPage         from "./pages/DigestsPage";
import EditUserPage        from "./pages/EditUserPage";
import ErrorPage           from "./pages/Error";
import FactorsPage         from "./pages/FactorsPage";
import InstitutionsPage    from "./pages/InstitutionsPage";
import LoginPage           from "./pages/LoginPage";
import MetadataPage        from "./pages/MetadataPage";
import NewUserPage         from "./pages/NewUserPage";
import NotFoundPage        from "./pages/NotFound";
import PipelinesPage       from "./pages/PipelinesPage";
import UsersManagementPage from "./pages/UsersManagementPage";
import UserViewPage        from "./pages/UserViewPage";
import MainShell           from "./shells/MainShell";
import { AppRoutes }       from "./utils/routes";

function App() {
  const renderProtectedRoute = ( element: any ): any => {
    // return !!localStorage.getItem( "password" ) ? element :  <Navigate to={AppRoutes.Login} replace={true}/> ;
    return element;
  };
  
  const queryClient: QueryClient = new QueryClient();
  return (
    <QueryClientProvider client={queryClient}>
      <Routes>
        <Route path={AppRoutes.Login} element={<LoginPage/>}/>
        <Route path={AppRoutes.Error} element={<ErrorPage/>}/>
        <Route path="*" element={<NotFoundPage/>}/>
        {/* Protected*/}
        <Route element={<MainShell/>}>
          <Route path={AppRoutes.Users}
                 element={renderProtectedRoute( <UsersManagementPage/> )}/>
          <Route path={AppRoutes.UserView}
                 element={renderProtectedRoute( <UserViewPage/> )}/>
          <Route path={AppRoutes.UserEdit}
                 element={renderProtectedRoute( <EditUserPage/> )}/>
          <Route path={AppRoutes.UserNew}
                 element={renderProtectedRoute( <NewUserPage/> )}/>
          <Route path={AppRoutes.Dashboard}
                 element={renderProtectedRoute( <DashboardPage/> )}/>
          <Route path={AppRoutes.Metadata}
                 element={renderProtectedRoute( <MetadataPage/> )}/>
          <Route path={AppRoutes.Calcs}
                 element={renderProtectedRoute( <CalcsPage/> )}/>
          <Route path={AppRoutes.Pipelines}
                 element={renderProtectedRoute( <PipelinesPage/> )}/>
          <Route path={AppRoutes.Digests}
                 element={renderProtectedRoute( <DigestsPage/> )}/>
          <Route path={AppRoutes.Factors}
                 element={renderProtectedRoute( <FactorsPage/> )}/>
          <Route path={AppRoutes.Institutions}
                 element={renderProtectedRoute( <InstitutionsPage/> )}/>
        </Route>
      </Routes>
    </QueryClientProvider>
  );
  
}

export default App;
