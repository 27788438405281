import { useQuery } from "react-query";
import { AdminRequests, getBasicAuth, submitRequest } from "../utils/routes";

export default function CommitHashDisplay() {
  const { isLoading, data, isError } = useQuery(
    [
      AdminRequests.GetCommitInfo,
      undefined,
      undefined,
      getBasicAuth(
        localStorage.getItem( "email" ) as string,
        localStorage.getItem( "password" ) as string,
      ),
    ],
    submitRequest,
    { staleTime: 1000 },
  );

  if (isLoading) {
    return <p>Loading commit hash...</p>;
  }

  if (isError || !data) {
    return <p>Error loading commit hash</p>;
  }

  return (
    <div className="text-center py-4">
      <p className="text-sm text-secondary">
        Latest Commit Hash:{" "}
        <span className="font-mono text-tertiary">
          {
          data.commit_hash === "Not set" 
          ? data.commit_hash : data.commit_hash.slice(0, 5)
          }
        </span>
      </p>
    </div>
  );
}
