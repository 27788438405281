import { ChevronLeftIcon } from "@heroicons/react/16/solid";
import React               from "react";
import { useNavigate }     from "react-router-dom";

interface BackArrowProps {
  text: string;
}

const BackArrow: React.FC<BackArrowProps> = ( { text } ) => {
  const navigate = useNavigate() as any;
  return (
    <a onClick={() => navigate( -1 )}
       className="inline-flex items-center gap-2 text-sm/6 text-zinc-500 dark:text-zinc-400 hover:cursor-pointer">
      <ChevronLeftIcon className="size-4 fill-zinc-400 dark:fill-zinc-500"/>
      {text}
    </a>
  );
};

export default BackArrow;


