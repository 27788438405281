import { create } from "zustand";

type GlobalState = {
  nightMode: boolean;
  setNightMode: ( nightMode: boolean ) => void;
  
  authenticated: boolean;
  setAuthenticated: ( loggedIn: boolean ) => void;
  
  user: string;
  email: string;
  password: string;
  
  selectedUser: string;
  setSelectedUser: ( user: string ) => void;
};

export const useGlobalStore: any = create<GlobalState>( ( set: any ): any => ( {
  
  nightMode: true,
  setNightMode: ( nightMode: boolean ): void => set(
    ( state: GlobalState ): any => ( { nightMode } ) ),
  
  authenticated: false,
  setAuthenticated: ( authenticated: boolean ): void => set(
    ( state: GlobalState ): any => ( { authenticated } ) ),
  
  user: "",
  setUser: ( user: string ): void => set(
    ( state: GlobalState ): any => ( { user } ) ),
  
  password: "",
  setPassword: ( password: string ): void => set(
    ( state: GlobalState ): any => ( { password } ) ),
  
  email: "",
  setEmail: ( email: string ): void => set(
    ( state: GlobalState ): any => ( { email } ) ),
  
  selectedUser: {},
  setSelectedUser: ( selectedUser: any ): void => set(
    ( state: GlobalState ): any => ( { selectedUser } ) ),
  
} ) );
