import { Transition }                   from "@headlessui/react";
import { XMarkIcon }                    from "@heroicons/react/20/solid";
import { CheckCircleIcon, XCircleIcon } from "@heroicons/react/24/outline";
import { FC, useState, useEffect }      from "react";
import { classNames }                   from "../utils/funcs";

const Notification: FC<any> = ( { title, message, error } ) => {
  const [ show, setShow ] = useState( false );
  
  useEffect(() => {
    const timer = setTimeout(() => {
      setShow(false);
    }, 3000);
    
    return () => clearTimeout(timer);
  }, []);
  
  return (
    <>
      {/* Global notification live region, render this permanently at the end of the document */}
      <div
        aria-live="assertive"
        className="pointer-events-none fixed inset-0 flex items-end px-4 py-6 sm:items-start sm:p-6"
      >
        <div className="flex w-full flex-col items-center space-y-4 sm:items-end">
          {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
          <Transition
            show={show}
            enter="transform ease-out duration-300 transition"
            enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            enterTo="translate-y-0 opacity-100 sm:translate-x-0"
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-primaryShadow shadow-lg ring-1 ring-black ring-opacity-5">
              <div className="p-4">
                <div className="flex items-center">
                  <div className="flex-shrink-0">
                    {error
                      ? <XCircleIcon className="h-6 w-6 text-danger" aria-hidden="true"/>
                      : <CheckCircleIcon className="h-6 w-6 text-success" aria-hidden="true"/>
                    }
                  </div>
                  <div className="ml-3 w-0 flex-1 pt-0.5 text-left">
                    <p className="text-sm font-medium text-secondaryShadow">{title}</p>
                    <p className="mt-1 text-sm text-secondary">{message}</p>
                  </div>
                  <div className="ml-4 flex flex-shrink-0">
                    <button
                      type="button"
                      className={classNames(
                        error ? "text-danger hover:text-dangerShadow" : "text-success hover:text-successShadow",
                        "inline-flex rounded-md bg-primaryShadow focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      )}
                      onClick={() => {
                        setShow( false );
                      }}
                    >
                      <span className="sr-only">Close</span>
                      <XMarkIcon className="h-5 w-5" aria-hidden="true"/>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Transition>
        </div>
      </div>
    </>
  );
};

export default Notification;
