import { EyeIcon } from "@heroicons/react/24/outline";
import { FC }      from "react";

type InstrumentResultsTableProps = {
  setSelectedTask: any,
  tasks: any[] | undefined
}

const TaskResultsTable: FC<InstrumentResultsTableProps> = ( {
  setSelectedTask,
  tasks,
} ) => {
  return !( tasks && tasks.length ) ? <></> : (
    <div>
      <span className="text-base font-semibold leading-6 text-secondary">Tasks</span>
      <div className="mx-auto max-w-7xl">
        <div className="bg-primary py-10">
          <div className="px-4 sm:px-6 lg:px-8">
            <div className="mt-8 flow-root">
              <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                  <table className="min-w-full divide-y divide-primaryShadow">
                    <thead>
                    <tr>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-secondary">
                        ID
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-secondary">
                        Name
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-secondary">
                        Task Class
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-secondary">
                        Quantity
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-secondary">
                        Completed
                      </th>
                      <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-secondary sm:pl-0">
                        Subtasks
                      </th>
                      <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-secondary sm:pl-0">
                        Subtasks Completed
                      </th>
                      <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0">
                        <span className="sr-only">Select</span>
                      </th>
                    </tr>
                    </thead>
                    <tbody className="divide-y divide-primaryShadow">
                    {tasks!.map( ( task: any ) => (
                      <tr key={task.name}>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-secondary">{task._id}</td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-secondary">{
                          task.name
                            .replace( /_/g, " " )
                            .replace( /\b\w/g, ( char: any ) => char.toUpperCase() )}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-secondary">{task.task_class}</td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-secondary">{task.quantity}</td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-secondary">{!!task.completed ? "Yes" : "No"}</td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-secondary">{!!task.batch_task_ids ? task.batch_task_ids.length || "N/A" : "N/A"}</td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-secondary">{!!task.completed_batch_tasks_ids ? task.completed_batch_tasks_ids.length || "N/A" : "N/A"}</td>
                        <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                          <EyeIcon className="h-6 w-6 text-tertiary hover:text-tertiaryShadow hover:cursor-pointer" aria-hidden="true"
                                   onClick={() => setSelectedTask( task )}/>
                        </td>
                      </tr>
                    ) )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default TaskResultsTable;