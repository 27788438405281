import UsersTable from "../components/UsersTable";

const UsersManagementPage = () => {
  return (
    <>
      <h1 className="text-base font-semibold leading-6 text-secondary">Users</h1>
      <UsersTable/>
    </>
  );
};

export default UsersManagementPage;