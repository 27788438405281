import { FC, useState }                               from "react";
import { useMutation }                                from "react-query";
import { AdminRequests, getBasicAuth, submitRequest } from "../utils/routes";
import ConfirmationDialog                             from "./ConfirmationDialog";

const RunPipeline: FC<any> = ( { pipeline } ) => {
  const [ open, setOpen ] = useState( false );
  
  const runPipelineMutation = useMutation( {
    mutationFn: submitRequest,
    onSuccess: ( data: any ): void => {
      console.log( "run pipeline:", pipeline );
      console.log( "data:", data );
    },
  } );
  
  const runPipeline = (): void => {
    runPipelineMutation.mutate( {
      queryKey: [
        AdminRequests.RunPipeline,
        undefined,
        pipeline,
        getBasicAuth(
          localStorage.getItem( "email" ) as string,
          localStorage.getItem( "password" ) as string,
        ),
      ],
    } );
  };
  
  return (
    <>
      <button
        type="button"
        className="block rounded-md bg-tertiary px-3 py-2 text-center text-sm font-semibold text-secondary hover:bg-tertiaryShadow focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-tertiary"
        onClick={() => setOpen( true )}
      >
        Run Pipeline
      </button>
      <ConfirmationDialog
        title="Run Pipeline"
        text={`Run a ${pipeline.full ? "full" : "partial"} ${pipeline.pipeline_type} pipeline ${pipeline.include_core ? "with" : "without"} the core?`}
        onConfirm={() => {
          console.log( "Pipeline " + pipeline + " starting." );
          runPipeline();
        }}
        open={open}
        setOpen={setOpen}
        danger={false}
      />
    </>
  );
};
export default RunPipeline;