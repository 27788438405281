import { FC, memo, useCallback, useState } from "react";

const SearchInput: FC<any> = ( { setSearchQuery } ) => {
  const [ localQuery, setLocalQuery ] = useState( "" );
  
  const handleQueryChange = useCallback( ( e: any ) => {
    setLocalQuery( e.target.value );
  }, [] );
  
  const handleKeyPress = useCallback( ( e: any ) => {
    if ( e.key === "Enter" ) {
      setSearchQuery( localQuery );
    }
  }, [ localQuery, setSearchQuery ] );
  
  return (
    <input
      id="search"
      name="search"
      className="block w-full rounded-md border-0 bg-primaryShadow py-1.5 pl-10 pr-3 text-secondary ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
      placeholder="Search"
      type="search"
      value={localQuery}
      onChange={handleQueryChange}
      onKeyDown={handleKeyPress}
    />
  );
};

export default memo( SearchInput );
