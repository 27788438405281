import InstrumentSearchTable from "../components/InstrumentSearchTable";

const MetadataPage = () => {
  return (
    <>
      <h1 className="text-base font-semibold leading-6 text-secondary">Instruments</h1>
      <br/>
      <div style={{ display: "flex", justifyContent: "flex-left" }}>
        <InstrumentSearchTable/>
      </div>
    </>
  );
};

export default MetadataPage;