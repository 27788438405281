import React, { memo, useState }                      from "react";
import { useQuery }                                   from "react-query";
import Loading                                        from "../components/Loading";
import PipelineResultsTable
                                                      from "../components/PipelineResultsTable";
import SubtaskResultsTable
                                                      from "../components/SubtaskResultsTable";
import TaskResultsTable
                                                      from "../components/TaskResultsTable";
import { AdminRequests, getBasicAuth, submitRequest } from "../utils/routes";

const PipelineDisplay = () => {
  const [ selectedPipeline, setSelectedPipeline ] = useState( null ) as any;
  const [ selectedTask, setSelectedTask ]         = useState( null ) as any;
  
  const {
          isLoading: pipelinesLoading,
          data: pipelines,
          isError: pipelinesError,
        } = useQuery(
    [
      AdminRequests.GetAllPipelines,
      undefined,
      undefined,
      getBasicAuth(
        localStorage.getItem( "email" ) as string,
        localStorage.getItem( "password" ) as string,
      ),
    ],
    submitRequest,
    {
      staleTime: 0,
      refetchInterval: 500,
    },
  );
  
  const {
          isLoading: tasksLoading,
          data: pipelineTasks,
          isError: tasksError,
        } = useQuery(
    [
      AdminRequests.GetPipelineTasks,
      { pipeline_id: !!selectedPipeline ? selectedPipeline.pipeline_id : null },
      undefined,
      getBasicAuth(
        localStorage.getItem( "email" ) as string,
        localStorage.getItem( "password" ) as string,
      ),
    ],
    submitRequest,
    {
      staleTime: 0,
      refetchInterval: 500,
    },
  );
  
  const {
          isLoading: subtasksLoading,
          data: subtasks,
          isError: subtasksError,
        } = useQuery(
    [
      AdminRequests.GetTaskSubtasks,
      {
        pipeline_id: !!selectedPipeline ? selectedPipeline.pipeline_id : null,
        task_name: !!selectedTask ? selectedTask.name : null,
      },
      undefined,
      getBasicAuth(
        localStorage.getItem( "email" ) as string,
        localStorage.getItem( "password" ) as string,
      ),
    ],
    submitRequest,
    {
      staleTime: 0,
      refetchInterval: 500,
    },
  );
  
  return (
    <>
      {pipelinesError ? <div>Error loading pipelines.</div> : (
        pipelinesLoading ? <Loading/> : (
          <div className="bg-primary mx-auto" style={{ width: "90%" }}>
            <PipelineResultsTable setSelectedPipeline={setSelectedPipeline} pipelines={pipelines}/>
          </div>
        )
      )}
      
      {tasksError ? <div>Error loading tasks.</div> : (
        tasksLoading ? <Loading/> : (
          <div className="bg-primary mx-auto" style={{ width: "90%" }}>
            <TaskResultsTable setSelectedTask={setSelectedTask} tasks={pipelineTasks}/>
          </div>
        )
      )}
      
      {subtasksError ? <div>Error loading subtasks.</div> : (
        subtasksLoading ? <Loading/> : (
          <div className="bg-primary mx-auto" style={{ width: "90%" }}>
            <SubtaskResultsTable subtasks={subtasks}/>
          </div>
        )
      )}
    
    </>
  );
};
export default memo( PipelineDisplay );