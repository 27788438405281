import React, { memo, useEffect, useState }           from "react";
import { useQuery }                                   from "react-query";
import InstrumentResultsTable
                                                      from "../components/InstrumentResultsTable";
import Loading                                        from "../components/Loading";
import SearchInput                                    from "../components/SearchBar";
import { AdminRequests, getBasicAuth, submitRequest } from "../utils/routes";

const InstrumentSearchTable = () => {
  const [ selectedInstrument, setSelectedInstrument ] = useState( null );
  const [ instrumentQuery, setInstrumentQuery ]       = useState( "" );
  
  const { isLoading, data: instruments, isError } = useQuery(
    [
      AdminRequests.MetadataSearch,
      { query: instrumentQuery },
      undefined,
      getBasicAuth(
        localStorage.getItem( "email" ) as string,
        localStorage.getItem( "password" ) as string,
      ),
    ],
    submitRequest,
    { staleTime: 60000, enabled: !!instrumentQuery },
  );
  
  useEffect( () => {
    if ( instruments && instruments.length === 1 ) {
      setSelectedInstrument( instruments[0] );
    }
  }, [ instruments ] );
  
  if ( isLoading ) {
    return <Loading/>;
  }
  
  if ( isError ) {
    return <div>Error loading instruments.</div>;
  }
  
  return (
    <div className="bg-primary mx-auto" style={{ width: "90%" }}>
      <div>
        <SearchInput setSearchQuery={setInstrumentQuery}/>
      </div>
      <InstrumentResultsTable instruments={instruments} selectedInstrument={selectedInstrument} setSelectedInstrument={setSelectedInstrument}/>
    </div>
  );
};
export default memo( InstrumentSearchTable );