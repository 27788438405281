import { EyeIcon } from "@heroicons/react/24/outline";

const DigestResultsTable = ( { setSelectedDigest, digests }: any ) => {
  console.log( digests );
  
  return !( digests && digests.length ) ? <></> : (
    <div>
      <span className="text-base font-semibold leading-6 text-secondary">Digests on Date</span>
      <div className="mx-auto max-w-7xl">
        <div className="bg-primary py-10">
          <div className="px-4 sm:px-6 lg:px-8">
            <div className="mt-8 flow-root">
              <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                  <table className="min-w-full divide-y divide-primaryShadow">
                    <thead>
                    <tr>
                      <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-secondary sm:pl-0">
                        User Email
                      </th>
                      <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-secondary sm:pl-0">
                        ingest_date
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-secondary">
                        Digest ID
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-secondary">
                        User ID
                      </th>
                      <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-secondary sm:pl-0">
                        model
                      </th>
                      <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0">
                        <span className="sr-only">Select</span>
                      </th>
                    </tr>
                    </thead>
                    <tbody className="divide-y divide-primaryShadow">
                    {digests!.map( ( digest: any ) => (
                      <tr key={digest._id}>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-secondary">{digest.user_email}</td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-secondary">{digest.ingest_date}</td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-secondary">{digest._id}</td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-secondary">{digest.user_id}</td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-secondary">{digest.model}</td>
                        <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                          <EyeIcon className="h-6 w-6 text-tertiary hover:text-tertiaryShadow hover:cursor-pointer" aria-hidden="true"
                                   onClick={() => setSelectedDigest( digest )}/>
                        </td>
                      </tr>
                    ) )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DigestResultsTable;