import { useState }    from "react";
import Dropdown        from "../components/Dropdown";
import PipelineDisplay from "../components/PipelineDisplay";
import PurgeQueues     from "../components/PurgeQueues";
import RunPipeline     from "../components/RunPipeline";
import Toggle          from "../components/Toggle";
import CommitHashDisplay from "../components/CommitHashDisplay";

const PipelinesPage = () => {
  const [ full, setFull ]               = useState( false );
  const [ includeCore, setIncludeCore ] = useState( false );
  const [ existingIngest, setExistingIngest ] = useState( true );
  const [ sendEmails, setSendEmails ] = useState( false );
  
  
  const options               = [
    { title: "Prices", description: "Price pipeline.", current: true },
    { title: "Metadata", description: "Metadata Pipeline.", current: false },
    { title: "Main", description: "Complete pipeline, end to end.", current: false },
    { title: "Core", description: "Core pipeline.", current: false },
  ];
  const [ option, setOption ] = useState( options[0] );

  const handlEexistingIngest= () => {
    setExistingIngest(!existingIngest);
    if (!existingIngest) {
      setFull(false);
    }
  };
  
  return (
    <>
      <h1 className="text-base font-semibold leading-6 text-secondary">Pipelines</h1>
      <br/>
      <br/>
      <CommitHashDisplay/>
      <br/>
      <br/>
      <Toggle checked={full} onChange={setFull} disabled = {existingIngest} title={"Full pipeline"}  description={"Whether to run the pipeline for all instruments or just the first couple hundred."}/>
      <br/>
      <br/>
      <Toggle checked={includeCore} onChange={setIncludeCore} title={"Core Pipeline"} description={"Whether to include the core part of the pipeline or just the quickly repeatable part."}/>
      <br/>
      <br/>
      <Toggle checked={existingIngest}  onChange={handlEexistingIngest} title={"Run only for existing interests"} description={"Whether to run the pipline for all instruments or only for interests specified by the users."}/>
      <br/>
      <br/>
      <Toggle checked={sendEmails}  onChange={setSendEmails} title={"Send emails after pipeline"} description={"Whether to send emails containing user digests for all users once pipeline finishes, or just to QA ones."}/>
      <br/>
      <br/>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Dropdown selected={option} setSelected={setOption} options={options}/>
      </div>
      <br/>
      <RunPipeline pipeline={{
        full,
        include_core: includeCore,
        existing_ingest: existingIngest,
        send_emails: sendEmails,
        pipeline_type: option.title.toLowerCase(),
      }}/>
      <br/>
      <PurgeQueues/>
      <br/>
      <PipelineDisplay/>
    </>
  );
};

export default PipelinesPage;