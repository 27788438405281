import React, { useState }                            from "react";
import { useQuery }                                   from "react-query";
import DateInput                                      from "../components/DateInput";
import DigestResultsTable
                                                      from "../components/digests/DigestResultsTable";
import DigestView
                                                      from "../components/digests/DigestView";
import Loading                                        from "../components/Loading";
import { AdminRequests, getBasicAuth, submitRequest } from "../utils/routes";

// Define the type for a single digest
interface Digest {
  id: string;
  email: string;
  date: string;
  content: string;
  // Add other properties as needed
}

const DigestsPage: React.FC = () => {
  const today = new Date().toISOString()
    .split( "T" )[0];
  
  const [ dateValue, setDateValue ]           = useState<string>( today );
  const [ selectedDigest, setSelectedDigest ] = useState<Digest | null>( null );
  
  const {
          isLoading: digestsLoading,
          data: digests,
          isError: digestsError,
        } = useQuery<Digest[], Error>(
    [
      AdminRequests.GetDigests,
      { digest_date: dateValue },
      undefined,
      getBasicAuth(
        localStorage.getItem( "email" ) as string,
        localStorage.getItem( "password" ) as string,
      ),
    ],
    submitRequest,
    {
      staleTime: 0,
      refetchInterval: 500,
    },
  );
  
  React.useEffect( () => {
    if ( digests && digests.length > 0 && !selectedDigest ) {
      setSelectedDigest( digests[0] );
    }
  }, [ digests, selectedDigest ] );
  
  if ( digestsLoading ) {
    return <Loading/>;
  }
  if ( digestsError ) {
    return <div className="text-red-500">Error loading digests.</div>;
  }
  
  return (
    <div className="bg-gray-900 min-h-screen text-gray-100 p-6">
      <div className="max-w-7xl mx-auto space-y-6">
        <div className="bg-gray-800 rounded-lg p-6">
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-xl font-semibold">Select Date</h2>
            <DateInput
              dateValue={dateValue}
              setDateValue={setDateValue}
              className="bg-primary text-white p-2 rounded-md w-48"
            />
          </div>
          {selectedDigest && (
            <div className="bg-gray-700 rounded-lg p-4 relative">
              <div className="flex justify-between items-start mb-2">
                <div>
                  <div className="text-sm text-gray-400">{selectedDigest.email}</div>
                  <div className="text-xs text-gray-500">{selectedDigest.date}</div>
                </div>
              </div>
              <DigestView
                digest={selectedDigest}
              />
            </div>
          )}
        </div>
        <div className="bg-gray-800 rounded-lg p-6">
          <h2 className="text-xl font-semibold mb-4">Digest Results</h2>
          <div className="text-sm text-gray-400 mb-2">Digests on Date</div>
          {digests && (
            <DigestResultsTable
              setSelectedDigest={setSelectedDigest}
              digests={digests}
              className="w-full text-gray-300"
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default DigestsPage;