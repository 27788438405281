import { FC } from "react";

type InstrumentResultsTableProps = {
  subtasks: any[] | undefined
}

const SubtaskResultsTable: FC<InstrumentResultsTableProps> = ( {
  subtasks,
} ) => {
  return !( subtasks && subtasks.length ) ? <></> : (
    <div>
      <span className="text-base font-semibold leading-6 text-secondary">Subtasks</span>
      <div className="mx-auto max-w-7xl">
        <div className="bg-primary py-10">
          <div className="px-4 sm:px-6 lg:px-8">
            <div className="mt-8 flow-root">
              <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                  <table className="min-w-full divide-y divide-primaryShadow">
                    <thead>
                    <tr>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-secondary">
                        ID
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-secondary">
                        Name
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-secondary">
                        Completed
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-secondary">
                        Number of Subjects
                      </th>
                      <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-secondary sm:pl-0">
                        First Subject
                      </th>
                    </tr>
                    </thead>
                    <tbody className="divide-y divide-primaryShadow">
                    {subtasks!.map( ( subtask: any ) => (
                      <tr key={subtask._id}>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-secondary">{subtask.task_id}</td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-secondary">{
                          subtask.name
                            .replace( /_/g, " " )
                            .replace( /\b\w/g, ( char: any ) => char.toUpperCase() )}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-secondary">{!!subtask.completed ? "Yes" : "No"}</td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-secondary">{subtask.subjects.length}</td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-secondary">{subtask.subjects[0]}</td>
                      </tr>
                    ) )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default SubtaskResultsTable;