import React from 'react';

interface TextInputProps {
  inputValue: string;
  setInputValue: (value: string) => void;
  handleEnter: () => void;
}

const TickerInput: React.FC<TextInputProps> = ({ inputValue, setInputValue, handleEnter }) => {
  
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value.toUpperCase());
  };
  
  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleEnter();
    }
  };
  
  return (
    <div className="max-w-md mx-auto mt-8">
      <input
        type="text"
        value={inputValue}
        onChange={handleInputChange}
        onKeyDown={handleKeyDown}
        placeholder="Example: AAPL"
        className="w-full px-3 py-2 text-secondary bg-primaryShadow border rounded-lg focus:outline-none focus:border-tertiary"
      />
    </div>
  );
};

export default TickerInput;