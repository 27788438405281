import { FC } from "react";

const DateInput: FC<any> = ( { dateValue, setDateValue } ) => {
  
  const handleChange = ( e: React.ChangeEvent<HTMLInputElement> ) => {
    setDateValue( e.target.value );
  };
  
  return (
    <input
      type="date"
      className="bg-primary text-secondary w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-primary focus:border-primary"
      value={dateValue}
      onChange={handleChange}
    />
  );
};

export default DateInput;
