import { Dialog, DialogPanel, Transition, TransitionChild } from "@headlessui/react";
import {
  Bars3Icon,
  ChartBarIcon,
  ClipboardIcon,
  EnvelopeIcon,
  UserCircleIcon,
  UsersIcon,
  WrenchIcon,
  XMarkIcon,
  BuildingLibraryIcon, SwatchIcon, CalculatorIcon,
} from "@heroicons/react/24/outline";
import { useState }       from "react";
import { Outlet }         from "react-router-dom";
import { classNames }     from "../utils/funcs";
import { AppRoutes }      from "../utils/routes";

const isCurrent = ( href: string ): boolean => {
  const page: string = window.location.pathname;
  if ( page in [ AppRoutes.UserView, AppRoutes.UserEdit, AppRoutes.UserNew ] ) {
    return page === AppRoutes.Users;
  }
  if ( page in [ AppRoutes.InstrumentView ] ) {
    return page === AppRoutes.Metadata;
  }
  return page === href;
};

const navigation = [
  { name: "Users", href: AppRoutes.Users, icon: UsersIcon, current: isCurrent( AppRoutes.Users ) },
  { name: "Digests", href: AppRoutes.Digests, icon: EnvelopeIcon, current: isCurrent( AppRoutes.Digests ) },
  { name: "Metadata", href: AppRoutes.Metadata, icon: SwatchIcon, current: isCurrent( AppRoutes.Metadata ) },
  { name: "Calcs", href: AppRoutes.Calcs, icon: CalculatorIcon, current: isCurrent( AppRoutes.Calcs ) },
  { name: "Factors", href: AppRoutes.Factors, icon: ChartBarIcon, current: isCurrent( AppRoutes.Factors ) },
  { name: "Institutions", href: AppRoutes.Institutions, icon: BuildingLibraryIcon, current: isCurrent( AppRoutes.Institutions ) },
  { name: "Pipelines", href: AppRoutes.Pipelines, icon: WrenchIcon, current: isCurrent( AppRoutes.Pipelines ) },
  { name: "Dashboard", href: AppRoutes.Dashboard, icon: ClipboardIcon, current: isCurrent( AppRoutes.Dashboard ) },
];

const MainShell = () => {
  const [ sidebarOpen, setSidebarOpen ] = useState( false );
  
  return (
    <>
      <div>
        <Transition show={sidebarOpen}>
          <Dialog className="relative z-50 lg:hidden" onClose={setSidebarOpen}>
            <TransitionChild
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-primary/80"/>
            </TransitionChild>
            
            <div className="fixed inset-0 flex">
              <TransitionChild
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <DialogPanel className="relative mr-16 flex w-full max-w-xs flex-1">
                  <TransitionChild
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                      <button type="button" className="-m-2.5 p-2.5" onClick={() => setSidebarOpen(
                        false )}>
                        <span className="sr-only">Close sidebar</span>
                        <XMarkIcon className="h-6 w-6 text-secondary" aria-hidden="true"/>
                      </button>
                    </div>
                  </TransitionChild>
                  {/* Sidebar component, swap this element with another sidebar if you like */}
                  <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-primary px-6 pb-2 ring-1 ring-white/10">
                    <div className="flex h-16 shrink-0 items-center">
                      <img
                        className="h-8 w-auto"
                        src="/logo512.png"
                        alt="Your Company"
                      />
                    </div>
                    <nav className="flex flex-1 flex-col">
                      <ul role="list" className="flex flex-1 flex-col gap-y-7">
                        <li>
                          <ul role="list" className="-mx-2 space-y-1">
                            {navigation.map( ( item ) => (
                              <li key={item.name}>
                                <a
                                  href={item.href}
                                  className={classNames(
                                    item.current
                                      ? "primaryShadow text-secondary"
                                      : "text-secondary hover:text-secondary hover:primaryShadow",
                                    "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold",
                                  )}
                                >
                                  <item.icon className="h-6 w-6 shrink-0" aria-hidden="true"/>
                                  {item.name}
                                </a>
                              </li>
                            ) )}
                          </ul>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </DialogPanel>
              </TransitionChild>
            </div>
          </Dialog>
        </Transition>
        
        {/* Static sidebar for desktop */}
        <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
          {/* Primary color must be manually entered here as background */}
          <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-gray-900 px-6">
            <div className="flex h-16 shrink-0 items-center">
              <img
                className="h-8 w-auto"
                src="/logo512.png"
                alt="Alpen Logo"
              />
            </div>
            <nav className="flex flex-1 flex-col">
              <ul role="list" className="flex flex-1 flex-col gap-y-7">
                <li>
                  <ul role="list" className="-mx-2 space-y-1">
                    {navigation.map( ( item ) => (
                      <li key={item.name}>
                        <a
                          href={item.href}
                          className={classNames(
                            item.current
                              ? "bg-primaryShadow text-tertiary"
                              : "text-secondary hover:text-tertiary hover:bg-primaryShadow",
                            "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold",
                          )}
                        >
                          <item.icon className="h-6 w-6 shrink-0" aria-hidden="true"/>
                          {item.name}
                        </a>
                      </li>
                    ) )}
                  </ul>
                </li>
                <li className="-mx-6 mt-auto">
                  <span
                    className="flex items-center gap-x-4 px-6 py-3 text-sm font-semibold leading-6 text-secondary"
                  >
                    <UserCircleIcon className="h-6 w-6" aria-hidden="true"/>
                    {localStorage.getItem( "user" )}
                  </span>
                </li>
              </ul>
            </nav>
          </div>
        </div>
        
        <div className="sticky top-0 z-40 flex items-center gap-x-6 bg-primary px-4 py-4 shadow-sm sm:px-6 lg:hidden">
          <button type="button" className="-m-2.5 p-2.5 text-secondary lg:hidden" onClick={() => setSidebarOpen(
            true )}>
            <span className="sr-only">Open sidebar</span>
            <Bars3Icon className="h-6 w-6" aria-hidden="true"/>
          </button>
          <div className="flex-1 text-sm font-semibold leading-6 text-secondary">Dashboard</div>
          {/*<a href="#" className="text-secondary">*/}
          {/*  <CogIcon className="h-6 w-6" aria-hidden="true"/>*/}
          {/*</a>*/}
        </div>
        
        <main className="py-10 lg:pl-72">
          <div className="px-4 sm:px-6 lg:px-8"><Outlet/></div>
        </main>
      </div>
    </>
  );
};
export default MainShell;